import DataBox from "@/components/generic/dataBox";
import { ButtonWrapper } from "@/components/generic/form/buttonWrapper";
import Password from "@/components/generic/form/password";
import Header from "@/components/generic/header";
import Layout from "@/components/layout";
import { UserUpdateInput, useUpdateUserMutation, useUserDataQuery } from "@/generated";
import { passwordRegex, passwordRule } from "@/helper/password";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

const AccountPage: React.FC<PageProps> = () => {
  const { data, loading: userLoading } = useUserDataQuery({ notifyOnNetworkStatusChange: true });
  const [updateUser, mutationStatus] = useUpdateUserMutation({ notifyOnNetworkStatusChange: true });
  const [honeypot, setHoneypot] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState<false | string>(false);
  const [message, setMessage] = useState<false | string>(false);

  const loading = mutationStatus.loading || userLoading;

  useEffect(() => {
    if (data?.authenticatedItem?.displayName) {
      setDisplayName(data.authenticatedItem.displayName);
    }
  }, [data?.authenticatedItem]);

  useEffect(() => {
    if (mutationStatus.error) {
      console.error(mutationStatus.error);
    }
  }, [mutationStatus.error]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    }
  }, [message]);

  const resetPasswordInputs = () => {
    setPassword1("");
    setPassword2("");
  };

  const resetMessages = () => {
    setError(false);
    setMessage(false);
  };

  return (
    <Layout title="Mijn Account">
      {data?.authenticatedItem && (
        <Container maxWidth="lg" sx={{ paddingX: 0 }}>
          <Header>{data.authenticatedItem.displayName}</Header>

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              resetMessages();

              const inputData: UserUpdateInput = {
                displayName,
              };

              if (password1 || password2) {
                if (password1 !== password2) {
                  resetPasswordInputs();
                  setError("Wachtwoorden komen niet overeen.");

                  return;
                } else if (!password1.match(passwordRegex)) {
                  resetPasswordInputs();
                  setError(passwordRule);

                  return;
                } else {
                  inputData.password = password1;
                }
              }

              await updateUser({
                variables: {
                  id: data?.authenticatedItem?.id,
                  data: inputData,
                },
              });

              if (inputData.password) {
                setMessage("Wachtwoord reset.");
              }

              resetPasswordInputs();
            }}
          >
            <DataBox loading={false} header="Jou gegevens">
              <TextField
                type="text"
                value={honeypot}
                onChange={(e) => setHoneypot(e.currentTarget.value)}
                disabled={loading}
                sx={{ display: "none" }}
              />
              <TextField
                type="text"
                defaultValue={data.authenticatedItem.email}
                label="Email"
                disabled={true}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                label="Naam"
                disabled={loading}
              />
              <Divider variant="middle" sx={{ marginY: 2 }} />
              <Typography variant="body2" component="p" color="primary.main">
                {passwordRule}
              </Typography>
              <Password
                id="password1"
                label="Nieuw wachtwoord"
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
              <Password
                id="password2"
                label="Wachtwoord controle"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {error && (
                <Typography variant="caption" component="p" color="error.main">
                  {error}
                </Typography>
              )}
              {message && (
                <Typography variant="caption" component="p" color="primary.light">
                  {message}
                </Typography>
              )}
              <ButtonWrapper sx={{ marginBottom: 2 }}>
                <Button variant="contained" type="submit" disabled={loading} tabIndex={3}>
                  update
                </Button>
              </ButtonWrapper>
            </DataBox>
          </form>
        </Container>
      )}
    </Layout>
  );
};

export default AccountPage;
